import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL:"https://www.dhdq.net/os_m_en_bs/"
    // baseURL:"http://127.0.0.1:9090/os_m_en_bs/"
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
