<template>
  <div v-cloak v-clickoutside="outsideClose" class="dropmain">
    <div @click="show = !show" class="dropbutton">
      Menu
      <div id="nav" class="dropdown" v-show="show">
        <router-link class="link" to="/products">Products</router-link>
        <router-link class="link" to="/accessories">Accessory</router-link>

        <hr />

        <router-link class="link" to="/projects">Projects</router-link>
        <router-link class="link" to="/solution">Solution</router-link>
        <router-link class="link" to="/activites">Activity</router-link>
        <router-link class="link" to="/aboutus">AboutUs</router-link>
        <router-link class="link" to="/service">Service</router-link>
      </div>
    </div>

    <!-- <router-link class="link" to="/customize">Customize</router-link> -->
    <router-link class="link" to="/contact">Contact</router-link>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  data() {
    return {
      show: false,
    };
  },
  directives: {
    clickoutside: {
      bind(el, binding, vnode) {
        function documentHandler(e) {
          if (el.contains(e.target)) {
            return false;
          }
          if (binding.expression) {
            binding.value(e);
          }
        }

        function KeyUp(e) {
          if (e.keyCode == 27) {
            if (binding.expression) {
              binding.value(e);
            }
          }
        }
        el.__vueClickOutSize__ = documentHandler;
        el.__vueKeyup__ = KeyUp;

        document.addEventListener("keyup", KeyUp);
        document.addEventListener("click", documentHandler);
      },
      unbind(el, binding) {
        document.removeEventListener("click", el.__vueClickOutSize__);
        delete el.__vueClickOutSize__;

        document.removeEventListener("keyup", el.__vueKeyup__);
        delete el.__vueKeyup__;
      },
    },
  },
  methods: {
    outsideClose() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
[v-cloak] {
  display: none;
}

.dropmain {
  width: auto;
  display: flex;
  justify-content: flex-end;
}

.dropbutton,
.dropmain > .link {
  display: block;
  padding: 5px 7px;
height: 30px;
line-height: 20px;
  color: #fff;
  background-color: #1d97b4;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  user-select: none;
margin-left: 5px;
position: relative;
}

.dropdown {
  /* width: 70px; */
  padding: 5px;
  font-size: 16px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  text-align: left;
  margin-top: 2px;
  position: absolute;
  top: 34px;
left: 0px;
  display: flex;
  flex-flow: column;
  z-index: 999;
  > .link:hover {
    background: #19939b;
    border: 4px;
    color: white;
  }
  > .link {
    background: #e8e8e8;
    border-radius: 4px;
    color: #19939b;
    margin: 2px 0px;
    text-align: center;
    padding: 2px;
  }

hr {
  margin: 2px 0px;
    height: 3px;
    border: none;
background: #e8e8e8;
}

}
</style>
