<template>
  <div class="activityItem">
    <!-- intro -->
    <div class="intro">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in productList"
            :key="index"
          >
            <van-row>

              <van-col span="24">
                <router-link class="link" :to="'/product/' + item.uuid">
                  <img :src="item.displaySrc" alt=""/>
                </router-link>
              </van-col>
              <van-col span="24"
                ><router-link class="link" :to="'/product/' + item.uuid">{{
                  item.name
                }}</router-link></van-col
              >
              <van-col span="24" v-if="productBtnCommonList[0]">
                <van-col span="8">
                  <i
                    class="iconfont"
                    :class="productBtnCommonList[0].iconCode"
                  ></i>
                  <a :href="productBtnCommonList[0].link">{{ productBtnCommonList[0].copywriting }}</a>
                </van-col>
                <van-col span="8">
                  <i
                    class="iconfont"
                    :class="productBtnCommonList[1].iconCode"
                  ></i>
                  <a :href="productBtnCommonList[1].link">{{ productBtnCommonList[1].copywriting }}</a>
                </van-col>
                <van-col span="8">
                  <i
                    class="iconfont"
                    :class="productBtnCommonList[2].iconCode"
                  ></i>
                  <a :href="productBtnCommonList[2].link">{{ productBtnCommonList[2].copywriting }}</a>
                </van-col>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
    </div>
    <!-- intro -->

    <!-- text -->
    <div class="text">
      <div class="swiper-container 
swiper-no-swiping">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in productList"
            :key="index"
          >
            <p class="sup">{{ item.title }}</p>
            <p class="sub">{{ item.useage }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- intro -->
  </div>
</template>
<script>
import Swiper from "swiper";
import jquery from "jquery";

export default {
  data() {
    return {
      productList: [],
      productBtnCommonList: [],
    };
  },
  methods: {},
  mounted() {
    var text = new Swiper(".text .swiper-container", {
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });

    var intro = new Swiper(".intro .swiper-container", {

      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows : true,
      },

      // slidesPerView: 3,
      // effect: 'coverflow',
      // centeredSlides: true,
      // spaceBetween: 10,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      controller: {
        control: text,
      },
    });

    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "productItem/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.productList = res._05_response_body_multiple;
      },
    });

    jquery.ajax({
      url: baseURL + "productBtnCommon/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.productBtnCommonList = res._05_response_body_multiple;
      },
    });
  },
};
</script>

<style lang="less" scoped>
.intro {
  // height: 200px;
  margin: 20px 0px;
  .swiper-container,
  .swiper-wrapper,
  .swiper-slide {
    height: 100%;
  }
  .swiper-slide-active {
    // width: 80%!important;
    margin: 0 auto;
  }
  .swiper-slide {
    
    text-align: center;
    padding: 4px;
    border: 1px solid #e9e9e9;
    background: #fffdfd;
    .van-row {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        width: 100%;
      }
      i {
        display: block;
      }
      a {
        color: #000 !important;
      }
    }
  }
}

.text {
  height: 60px;
  padding: 4px;
  text-align: center;
  margin: 20px auto;
}
</style>
