<!--  -->
<template>
  <div class="detailBox">
    <div class="title">Product Detail</div>

    <div class="swiper-container details">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in productDetailList"
          :key="index"
        >
          <img v-image-preview :src="item.imgSrc" :alt="item.remark" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import jquery from "jquery";
export default {
  data() {
    return {
      pid: this.uuid,
      productDetailList: {},
    };
  },
  methods: {},
  props: ["uuid"],
  mounted() {
    var details = new Swiper(".details", {
      spaceBetween: 5,
      slidesPerView: 5,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });
  },
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "productDetail/find/pid",
      method: "POST",
      data: JSON.stringify({ pId: that.pid }),
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.productDetailList = res._05_response_body_multiple;
      },
    });
  },
};
</script>
<style lang="less" scoped>
.detailBox {
  padding: 8px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .swiper-slide {
    img {
      width: 100%;
    }
  }
}
</style>
