<!--  -->
<template>
  <div class="container" v-if="contactData">
    <van-row class="title">
      <van-col span="24"
        ><p>{{ contactData.titleI }}</p></van-col
      >
    </van-row>
    <van-row class="content">
      <van-col span="24"
        ><p v-html="contactData.titleIi">
        </p></van-col
      >
    </van-row>
    <van-row class="panel">
      <img v-image-preview class="bg" :src="contactData.imgSrc" alt="" />
      <van-col span="10" class="contactbox">
        <van-col span="24"
          ><input type="text" v-model="msgData.name" placeholder="name"
        /></van-col>
        <van-col span="24"
          ><input type="text" v-model="msgData.email" placeholder="email"
        /></van-col>
        <van-col span="24"
          ><input type="text" v-model="msgData.phone" placeholder="phone"
        /></van-col>
        <van-col span="24"
          ><textarea
            type="text"
            v-model="msgData.content"
            placeholder="question"
          ></textarea
        ></van-col>
        <van-col span="24"
          ><van-button @click="checkMSGData()" type="primary" size="large"
            >Submit</van-button
          >
<van-button @click="checkMSGDataInit()" type="danger" size="large"
            >Reset</van-button
          >
        </van-col>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import jquery from "jquery";
export default {
  data() {
    return {
      contactData: null,
      msgData: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
    };
  },
  mounted() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "contactUsIntro/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.contactData = res._04_response_body_single;
      },
    });
  },

  methods: {
    checkMSGDataInit: function() {
      this.msgData.name = "";
      this.msgData.email = "";
      this.msgData.phone = "";
      this.msgData.content = "";
    },
    postMessage: function() {
      const baseURL = this.$store.state.baseURL;
      let that = this;
      jquery.ajax({
        url: baseURL + "message/insert",
        method: "POST",
        data: JSON.stringify(this.msgData),
        contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
        dataType: "JSON", //指定 返回 数据 类型
        success: function(res) {
          that.checkMSGDataInit();
          alert("success");
        },
      });
    },

    checkMSGData: function() {
      if (this.msgData.name.length < 1) {
        alert("name is null !!!");
        return;
      } else if (this.msgData.email.length < 1) {
        alert("email is null !!!");
        return;
      } else if (this.msgData.phone.length < 1) {
        alert("phone is null !!!");
        return;
      } else if (this.msgData.content.length < 1) {
        alert("message is null !!!");
        return;
      }

      this.postMessage();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  .title {
    p {
      font-size: 22px;
      color: #19939b;
      font-weight: bold;
      margin: 8px 0px;
    }
  }
  .content {
    p {
      font-size: 16px;
      color: #a3a3a3;
      margin: 8px 0px;
    }
  }
  .panel {
    // height: 300px;
    // background: #19939b;
    position: absolute;

    .contactbox {
      position: relative;
      top: 30px;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // border: 1px solid black;
      z-index: -1;
      opacity: 0.8;
    }

    input,
    textarea,
    .van-button {
      width: 100%;
      margin: 5px;
      padding: 2px;
      outline: none;
    }
    .van-button {
      background: #19939b;
      padding: 4px;
      height: auto;
      border: none;
    }
  }
}
</style>
