<!--  -->
<template>
  <div class="solutionBox">
    <van-row class="imgBox" v-if="solution != null">
      <van-col span="24">
        <img v-image-preview :src="solution.imgSrc" alt="img" />
      </van-col>
    </van-row>
    <van-row class="titleBox">
      <van-col span="7" class="raw">
        Raw materials
      </van-col>
      <van-col span="17" class="titleBar">
        <span
          class="titleItem"
          @click="slideTo(index)"
          v-for="(item,index) in bannerGroupI"
          :key="item.uuid"
          >{{ item.title }}</span
        >
      </van-col>
    </van-row>

    <van-row class="photoBox" v-if="bannerGroupIi != null">
      <van-col span="24">
        <van-swipe
          ref="swiperBox"
          class="photoSwipe"
          @change="photoChange"
          :autoplay="3000"
          indicator-color="white"
        >
          <van-swipe-item v-for="(item, index) in bannerGroupIi" :key="index">
            <div v-if="item.imgSrc != null">
              <img v-lazy="item.imgSrc" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem, Toast } from "vant";
import jquery from "jquery";
import { Lazyload } from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);

export default {
  data() {
    return {
      solution: null,
      bannerGroupI: null,
      bannerGroupIi: null,
    };
  },
  created() {
    const baseURL = this.$store.state.baseURL;
    let that = this;
    jquery.ajax({
      url: baseURL + "solution/find/ts",
      method: "POST",
      contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
      dataType: "JSON", //指定 返回 数据 类型
      success: function(res) {
        that.solution = res._04_response_body_single;

        //  获取标签数据
        jquery.ajax({
          url:
            baseURL +
            "solutionBanner/find/list/gid?gid=" +
            that.solution.bannerGroupI,
          method: "POST",
          contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
          dataType: "JSON", //指定 返回 数据 类型
          success: function(res) {
            that.bannerGroupI = res._05_response_body_multiple;
            console.log(that.bannerGroupI);
          },
        });
        //  获取标签数据

        //  获取方案数据
        jquery.ajax({
          url:
            baseURL +
            "solutionBanner/find/list/gid?gid=" +
            that.solution.bannerGroupIi,
          method: "POST",
          contentType: "application/json;charset=UTF-8", //指定 请求  数据 类型
          dataType: "JSON", //指定 返回 数据 类型
          success: function(res) {
            that.bannerGroupIi = res._05_response_body_multiple;
          },
        });
        //  获取方案数据
      },
    });
  },
  methods: {
    photoChange: function(index) {
      let titleItemList = document.querySelectorAll(".titleItem");
      for(let i = 0;i<titleItemList.length;i++){
        titleItemList[i].classList.remove("active");
      }
      titleItemList[index].classList.add("active");
    },
    slideTo: function(index) {
      let titleItemList = document.querySelectorAll(".titleItem");
      for(let i = 0;i<titleItemList.length;i++){
        titleItemList[i].classList.remove("active");
      }
      titleItemList[index].classList.add("active");
      this.$refs.swiperBox.swipeTo(index);
    },
  },
};
</script>
<style lang="less" scoped>
.solutionBox {
  padding: 8px;
  .imgBox {
    img {
      width: 100%;
    }
  }
  .titleBox {
    .raw {
      font-weight: bolder;
      color: black;
      display: flex;
      align-items: center;
    }
    background: #f3f3f3;
    color: #939393;
    display: flex;
    margin: 10px 0px;
    padding: 8px;
    .titleBar {
      display: flex;
      overflow-x: scroll;
    }
    .titleItem {
      // background: grey;
      padding: 4px 8px;
      margin: 0 8px;
    }
    .active {
      // background: #19939B;
      color: #1d97b4;
    }
  }
  .photoBox {
    img {
      width: 100%;
    }
    .photoSwipe {
      max-height: 200px!important;
    }
  }
}
</style>