import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//  npm install normalize.css
import 'normalize.css/normalize.css'

import "../node_modules/font-awesome/css/font-awesome.min.css";

// 视频播放组件
import vueMiniPlayer from "vue-mini-player";
import "vue-mini-player/lib/vue-mini-player.css";
Vue.use(vueMiniPlayer);

//iView-ViewUI
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

// 全局样式
import './assets/style.css'

//Vant 有赞
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import './assets/swiper.js'
import './assets/swiper.css'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer, {
  //1. wrapper背景颜色———— 你也可以写成background: '#000'  //支持rgba、rgb和image: 'url(xxx)'
  background: {    
    color: '#000' 
  },
  
  //2. 转换动画
  animate: {
    duration: 200,
    delay: 20
  },

  //3. 鼠标样式(css)
  cursor: 'pointer',

  //层级
  zIndex: 88804
}) 


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
